// Utils
import { isNil } from 'lodash'
// Mixin
import uiMixin from '@/mixins/uiMixin'

export default {
  mixins: [uiMixin],
  data() {
    return {
      printError:
        'El dispositivo con el que está intentando imprimir, no soporta el método de impresión, por favor pruebe con otro.'
    }
  },
  computed: {
    /**
     * Tiene método de impresión?
     *
     * @return {boolean}
     */
    hasWindowPrint() {
      return !isNil(window.print) && typeof window.print === 'function'
    }
  },
  methods: {
    /**
     * Toma el elemento indicado, lo inserta dentro de la equita "print-version"
     * e inicia la vista de impresión
     *
     * @param {string} selector - element DOM
     */
    printElement(selector) {
      const printVersion = document.querySelector('.print-version')
      const element = document.querySelector(selector)

      if (!isNil(element)) {
        // Clonamos el elemento que deseamos insertar
        const clonedElement = element.cloneNode(true)
        // Vaciamos el contenido previo
        printVersion.innerHTML = ''
        // Insertamos el nuevo contenido
        printVersion.append(clonedElement)
        // Pasamos a versión de impresión
        if (this.hasWindowPrint) {
          window.print()
        } else {
          this.modifyAppAlert({
            type: 'error',
            text: this.printError,
            visible: true
          })
        }
      }
    }
  }
}

// Constans
import { ADDONS } from '@/constants'
// Components
import AllergensIcons from '@/components/ui/AllergensIcons'
import CardContainer from '@/components/ui/CardContainer'
import CardContainerElement from '@/components/ui/CardContainerElement'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import formMixin from '@/mixins/formMixin'
import printMixin from '@/mixins/printMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getConfigById } from '@/services/config'
import {
  getCategoryById,
  getEveryMenusByPlaceId,
  getAvailableCategoriesAndProductsByCategoryId
} from '@/services/category'
import { getDefaultRations } from '@/services/ration'
// Utils
import { isNil, get } from 'lodash'
// Filters
import { formatPrice, nl2br } from '@/filters'
// utils
import { timeout } from '@/utils'

export default {
  name: 'PremiumMenuPreview',
  components: {
    AllergensIcons,
    CardContainer,
    CardContainerElement,
    FormButtons,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  mixins: [addonsMixin, formMixin, printMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        column: 'columns-1',
        language: null,
        menu: this.$route.params.id || null
      },
      formFieldsValidations: {
        column: {
          required: 'Campo obligatorio'
        },
        language: {
          required: 'Campo obligatorio'
        },
        menu: {
          menu: 'Campo obligatorio'
        }
      },
      // Others
      availableColumns: [
        {
          label: '1 columna',
          value: 'columns-1'
        },
        {
          label: '2 columnas',
          value: 'columns-2'
        }
      ],
      availableLanguages: [],
      availableMenus: [],
      items: [], // Categorías y productos de las cartas
      menuData: null, //Datos del menú
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('place', ['placeData', 'currencyData']),
    ...mapGetters('config', ['allergensData']),
    ...mapGetters('meta', ['rationsData']),
    ...mapState('app', ['largeDevice']),
    /**
     * Get "rationsData" like Object
     *
     * @return {Object}
     */
    rationDataObject() {
      return this.rationsData.reduce((sumRations, ration) => {
        const { id, ...last } = ration
        sumRations[id] = last
        return sumRations
      }, {})
    },
    /**
     * Get "allergensData" like Array
     *
     * @return {Object}
     */
    allergensDataParsed() {
      return Object.values(this.allergensData).reduce((sumAllergens, allergen) => {
        sumAllergens.push(allergen.id)
        return sumAllergens
      }, [])
    },
    /**
     * Clases de CSS del "items"
     */
    itemsCss() {
      return ['items', this.formFields.column].join(' ')
    },
    /**
     * Clases de CSS del "wrapper"
     */
    wrapperCss() {
      const css = ['print-menu-preview-wrapper']

      if (this.items.length === 0 || !this.largeDevice) {
        css.push('d-none-screen')
      }

      return css.join(' ')
    },
    /**
     * Precio del menú
     *
     * @return {string} - Precio del menú formateado
     */
    menuPrice() {
      const menuPrice = get(this.menuData, 'price', 0)
      return !isNil(menuPrice) && parseInt(menuPrice) > 0
        ? formatPrice(menuPrice, get(this.currencyData, 'code', 'EUR'))
        : false
    }
  },
  watch: {
    async selectedMenu(value) {
      await this.getEverythingByMenu(value)
    }
  },
  async mounted() {
    // Obtenemos los datos iniciales
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Cuando pulsamos en el botón de "Imprimir"
     */
    handleClickPrint() {
      this.printElement('.print-menu-preview-wrapper')
    },
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Obtenemos todos los datos necesarios para
     * inicializar el listado de categorías
     */
    async getEveryNeededData() {
      this.processingRequest = true
      try {
        // Menús disponibles
        this.availableMenus = await this.getAvailableMenus()
        // Idiomas disponibles
        this.availableLanguages = await this.getAvailableLanguages()
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos los menús disponibles del establecimiento
     *
     * @return {array}
     */
    async getAvailableMenus() {
      const menus = await getEveryMenusByPlaceId(this.placeData.id)
      // Solo devolvemos los datos básicos necesarios para el selector
      return menus.map((menu) => {
        return {
          value: menu.id,
          label: menu.name
        }
      })
    },
    /**
     * Obtenemos los idiomas disponibles del establecimiento
     *
     * @return {array}
     */
    async getAvailableLanguages() {
      // Todos los idiomas
      const { data } = await getConfigById('languages')
      // Idiomas del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      const placeLanguages = [
        placeConfig.defaultLanguage,
        ...(placeConfig.additionalLanguages || [])
      ]

      // Filtramos por los idiomas del establecimiento
      return data
        .reduce((sumLangs, lang) => {
          if (placeLanguages.indexOf(lang.id) > -1) {
            sumLangs.push({
              value: lang.id,
              label: lang.name
            })
          }
          return sumLangs
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label, 'es'))
    },
    /**
     * Obtenemos todos las categorías y productos de un menu.
     * Aprovechamos y "parsemos" los datos con el idioma seleccionado
     * y los "tipos de raciones"
     *
     * @param {string} id - UID menu
     * @param {string} lang - Idioma del menú
     * @return {array}
     */
    async getEverythingByMenu(id, lang) {
      try {
        // Todas las raciones del establecimiento
        const rationDataObject = this.rationDataObject
        // Raciones por defecto (claves) (precio único)
        const defaultRationsIds = Object.keys(getDefaultRations())
        // Todas las categorías y productos de la carta
        const items = await getAvailableCategoriesAndProductsByCategoryId(id)

        // Parseamos las categorías con el idioma seleccionado
        return items.reduce((sumItems, item) => {
          const category = item.category
          const dishes = item.dishes
          const someDishIsEnabled = dishes.some((dish) => {
            return !dish.disabled
          })

          // Si la categoría no está "deshabilitada" y existe
          // al menos un plato "activo" dentro de ella
          if (!category.disabled && someDishIsEnabled) {
            // Obtengo todos los tipos de raciones (ordenados)
            // de una categoría por los productos que la componen
            const everyCategoryPrices = dishes
              .reduce((sumDishesPrices, dish) => {
                const dishPrices = get(dish, `prices.${category.id}`, null)
                const dishPricesItems =
                  !isNil(dishPrices) &&
                  typeof dishPrices === 'object' &&
                  Object.keys(dishPrices).length > 0
                    ? Object.entries(dishPrices).map((price) => {
                        return {
                          id: price[0],
                          name:
                            price[0] !== 0 && rationDataObject[price[0]]
                              ? get(
                                  rationDataObject,
                                  `${price[0]}.translations.${lang}.name`,
                                  rationDataObject[price[0]].name
                                )
                              : get(this.currencyData, 'code', 'EUR'), // Precio único
                          // El "precio único" (o sin orden), se muestra en la columna final
                          order:
                            isNil(price[1].order) || defaultRationsIds.indexOf(price[0]) > -1
                              ? 10
                              : price[1].order
                        }
                      })
                    : null

                // Buscamos en el array general de precios (sumDishesPrices)
                // si los precios (raciones) del producto (dishPricesItems)
                // ya existen en él
                const pricesNotMatch = !isNil(dishPricesItems)
                  ? dishPricesItems.filter((dishPriceItem) => {
                      return !sumDishesPrices.some((sumDishPrice) => {
                        return dishPriceItem.id === sumDishPrice.id
                      })
                    })
                  : []

                return [...sumDishesPrices, ...pricesNotMatch]
              }, [])
              .sort((a, b) => {
                return a.order - b.order
              })

            // Inserto en el array los items a devolver
            sumItems.push({
              category: {
                name: get(category, `translations.${lang}.name`, category.name),
                prices: everyCategoryPrices
              },
              dishes: dishes.reduce((sumDishes, dish) => {
                if (!dish.disabled) {
                  const dishPrices = get(dish, `prices.${category.id}`, {})
                  sumDishes.push({
                    name: get(dish, `translations.${lang}.name`, dish.name),
                    description: nl2br(
                      get(dish, `translations.${lang}.description`, dish.description)
                    ),
                    allergens: dish.allergens,
                    prices: everyCategoryPrices.map((categoryPrice) => {
                      return {
                        value:
                          !isNil(dishPrices) &&
                          dishPrices[categoryPrice.id] &&
                          dishPrices[categoryPrice.id].price
                            ? formatPrice(
                                dishPrices[categoryPrice.id].price,
                                get(this.currencyData, 'code', 'EUR')
                              )
                            : null
                      }
                    })
                  })
                }
                return sumDishes
              }, [])
            })
          }
          return sumItems
        }, [])
      } catch (error) {
        this.handleError(error.message)
      }
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Datos del menú
      this.menuData = await getCategoryById(this.formFields.menu)
      // Obtenemos los elementos del menú
      this.items = await this.getEverythingByMenu(this.formFields.menu, this.formFields.language)

      // Imprimimos directamente si estamos sobre un móvil
      if (!this.largeDevice) {
        // Esperamos unos segundos para que el de tiempo a pintar
        await timeout(2000)
        // Imprimimos
        this.handleClickPrint()
      }
    },
    /**
     * Obtenemos la anchura de las columnas (cabeceras y precios)
     *
     * @param {number} number
     * @return {string}
     */
    stylePriceColumns(number) {
      return `width: ${100 / number}%`
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      column: {
        required
      },
      language: {
        required
      },
      menu: {
        required
      }
    }
  }
}
